<template>
    <main>
        <div class="ui center aligned container">
            <div class="ui hidden section divider"></div><div class="ui hidden section divider"></div><div class="ui hidden section divider"></div>
            <div class="ui medium image">
                <router-link :to="{ name: 'Home' }"><img class="logo" src="~@/assets/images/logo/1991spwg.png"/></router-link>
            </div>
            <div class="ui centered raised validation card">
                <transition name="slide-left">
                    <div class="content" v-if="requested">
                        <div class="center aligned header">
                            <div class="ui hidden medium divider"></div>
                            <i class="big red times circle outline icon" v-if="rejected"></i>
                            <i class="big purple shield alternate icon" v-else></i>
                            <div class="ui hidden medium divider"></div>
                            <div v-if="rejected" class="ui red text">Verification Rejected</div>
                            <div v-else>Waiting Approval</div>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="center aligned description">
                            <p v-if="rejected">Your request for device verification has been rejected. Please contact administrator for more detail.</p>
                            <p v-else>Please do not close or refresh this page. System will log you in once <strong>{{selected_admin|displayname}}</strong> has approved your verification request</p>
                        </div>
                    </div>
                </transition>
                <transition name="slide-left">
                    <div class="content" v-if="!requested">
                        <div class="center aligned header">
                            <div class="ui hidden medium divider"></div>
                            <i class="big purple shield alternate icon"></i>
                            <div class="ui hidden medium divider"></div>
                            <div>Unknown Device</div>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="center aligned description">
                            <p>This device is not authenticated. To continue, please request administrator to verify this device</p>
                        </div>
                    </div>
                </transition>
                <template v-if="requested && !rejected">
                    <div class="ui wave hidden divider">
                        <svg class="editorial"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 24 150 28 "
                            preserveAspectRatio="none">
                            <defs>
                                <path id="gentle-wave"
                                    d="M-160 44c30 0
                                    58-18 88-18s
                                    58 18 88 18
                                    58-18 88-18
                                    58 18 88 18
                                    v44h-352z"/>
                            </defs>
                            <g class="parallax1">
                                <use xlink:href="#gentle-wave" x="50" y="3" fill="#f461c1"/>
                            </g>
                            <g class="parallax2">
                                <use xlink:href="#gentle-wave" x="50" y="0" fill="#4579e2"/>
                            </g>
                            <g class="parallax3">
                                <use xlink:href="#gentle-wave" x="50" y="9" fill="#3461c1"/>
                            </g>
                            <g class="parallax4">
                                <use xlink:href="#gentle-wave" x="50" y="6" fill="#fff"/>
                            </g>
                        </svg>
                    </div>
                    <transition name="slide-left">
                        <div class="extra center aligned content" style="border-top: none !important;">
                            <div>
                                <p>Still not verified?</p>
                                <button type="button" class="ui fluid teal button" @click="initiateVerification">Resend Verification Request</button>
                            </div>
                            <div class="ui horizontal divider">Or</div>
                            <div>
                                <p>Copy the following link and send to any Administrator for verification</p>
                                <div class="ui left labeled teal fluid button" tabindex="0" title="Click to copy" @click="copyVerificationLink">
                                    <div class="ui basic teal verification-link label">
                                        {{verification_link}}
                                    </div>
                                    <div class="ui icon teal button">
                                        <i class="copy icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </template>

                <template v-if="!requested">
                    <transition name="slide-left">
                        <div class="extra center aligned content">
                            <a class="ui teal alt link fluid button" href="javascript:void(0);" @click="initiateVerification">
                                Verify Device
                            </a>
                        </div>
                    </transition>
                </template>

            </div>
        </div>

        <div ref="adminmodal" class="ui hidden mini modal">
            <div class="content">
                <h3 class="ui header">Choose an administrator to continue</h3>
                <form :id="`verifyadminform${_uid}`" class="ui form" action="javascript:void(0);" @submit="requestVerification">
                    <div class="grouped fields">
                        <div v-for="admin in admins" :key="`admin_${admin.id}`" class="field">
                            <div class="ui radio checkbox">
                                <input type="radio" name="form_admin" tabindex="0" :value="admin" v-model="form_admin" required/>
                                <label>{{admin|displayname}}</label>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="ui hidden divider"></div>
                <p>We will send the verification request to the selected administrator's WhatsApp</p>
            </div>
            <div class="actions">
                <button type="button" class="ui red alt link cancel button" :disabled="requesting">Cancel</button>
                <button type="submit" :form="`verifyadminform${_uid}`" class="ui submit teal button" :class="{loading: requesting}" :disabled="!form_admin">OK</button>
            </div>
        </div>
    </main>
</template>

<script>
import Role from "@/assets/constants/role";

export default {
    data() {
        return {
            token: this.$route.params?.token || "",
            user: this.$route.params?.user || null,
            admins: [],
            form_admin: null,
            selected_admin: null,
            requesting: false,
            requested: false,
            rejected: false
        };
    },
    created() {
        if(!this.token || !this.user) {
            this.$router.push({
                name: "Login"
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            $(this.$refs.adminmodal).modal();
        });
    },
    computed: {
        verification_link() {
            let verification_link = "";
            if(this._device_id) {
                let origin = window.location.origin;
                if(origin.endsWith("/")) {
                    origin = origin.substring(0, origin.length - 1);
                }

                let href = this.$router.resolve({
                    name: "ApproveDevice",
                    params: {
                        device_id: this._device_id
                    }
                }).href;

                verification_link = origin + href;
            }

            return verification_link;
        }
    },
    methods: {
        initiateVerification() {
            this.$http.get("users", {
                params: {
                    filter: JSON.stringify({
                        role: Role.ADMIN,
                        verified: true,
                        suspended: false
                    })
                },
                headers: {
                    "Authorization": `Bearer ${this.token}`,
                    "x-session-token": this.token
                }
            }).then((response) => {
                this.admins = response.data.filter((admin) => {
                    return admin.mobile_no;
                });

                this.$nextTick(() => {
                    $(this.$refs.adminmodal).find(".ui.radio.checkbox").checkbox();
                    $(this.$refs.adminmodal).modal("show");
                });
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable to list administrators to continue.", {
                    duration: 3000
                });
            });
        },
        requestVerification() {
            if(this.form_admin) {
                this.selected_admin = Object.assign({}, this.form_admin);

                let formdata = {
                    verified_by: this.selected_admin.id
                };

                if(this._device_id) {
                    formdata.id = this._device_id;
                }

                this.requesting = true;
                this.$http.post("devices/request", formdata, {
                    headers: {
                        "Authorization": `Bearer ${this.token}`,
                        "x-session-token": this.token
                    }
                }).then(({ data, headers, status }) => {
                    if(status === 205) {
                        $(this.$refs.adminmodal).modal("hide");

                        this.logUserIn();
                    } else {
                        const device = data;
                        const device_id = device?.id;

                        if(!device_id) {
                            $(this.$refs.adminmodal).modal("hide");

                            this.$toasted.error("Unable to generate device id for verification purpose. Please try again later.", {
                                duration: 3000
                            });
                        } else {
                            $(this.$refs.adminmodal).modal("hide");

                            this.$store.dispatch("auth/updateDeviceID", device_id).then(() => {
                                this.checkVerification();
                                this.requested = true;
                            });
                        }
                    }
                }).catch((error) => {
                    $(this.$refs.adminmodal).modal("hide");

                    console.error(error);
                    this.$toasted.error(`Unable to request ${this.$options.filters.displayname(this.selected_admin)} to verify. Please try again later.`, {
                        duration: 3000
                    });
                }).finally(() => {
                    this.requesting = false;
                });
            } else {
                this.$toasted.error("Please select an administrator to continue.", {
                    duration: 3000
                });
            }
        },
        checkVerification() {
            if(this._device_id) {
                this.$http.get(`devices/${this._device_id}`, {
                    headers: {
                        "Authorization": `Bearer ${this.token}`,
                        "x-session-token": this.token
                    }
                }).then((response) => {
                    const device = response.data;
                    if(device.verified) {
                        this.logUserIn();
                    } else {
                        setTimeout(() => {
                            this.checkVerification();
                        }, 5000);
                    }
                }).catch((error) => {
                    console.error(error);

                    if(404 === error.response?.status) {
                        this.rejected = true;
                        this.$store.dispatch("auth/updateDeviceID", "");

                        this.$toasted.error("Request to verify this device has been rejected.", {
                            action: {
                                text: "OK",
                                onClick: (e, toast) => {
                                    this.$router.push({
                                        name: "Login"
                                    });

                                    toast.goAway(0);
                                }
                            }
                        });
                    } else {
                        this.$toasted.error("Unable to check verification status. Please try again later.", {
                            duration: 3000
                        });
                    }
                });
            }
        },
        logUserIn() {
            this.$store.dispatch("auth/login", {
                auth_token: this.token,
                user: this.user
            }).then(() => {
                this.$router.push({
                    name: "Home"
                });
            }).catch((error) => {
                console.error(error);
                this.$router.push({
                    name: "Login"
                });
            });
        },
        copyVerificationLink() {
            try {
                let $input = $("<input/>", {
                    "type": "text",
                    "readonly": true,
                    "value": this.verification_link,
                    "style": "position: absolute; left: -99999999px;"
                }).appendTo("body");

                $input.select();
                document.execCommand("copy");
                this.$toasted.show("Verification copied to clipboard.", {
                    duration: 3000
                });
            } catch(error) {
                console.error(error);
                this.$toasted.error("Unable to copy the verification link.", {
                    duration: 3000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.validation.card {
    overflow: hidden;
    width: 320px;
}

.ui.labeled.button {
    > .verification-link.label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        padding: .78571429em .78571429em .78571429em;
    }
}

.slide-left-enter {
    opacity: 0;
    transform: translate(100%, 0);
}

.slide-left-enter-to {
    opacity: 1;
    transform: none;
}

.slide-left-leave {
    opacity: 1;
    transform: none;
}

.slide-left-leave-to {
    opacity: 0;
    transform: translate(-100%, 0);
}

.slide-left-leave-active {
    position: absolute;
}

.slide-left-enter-active, .slide-left-leave-active {
    transition: all 0.5s;
}

.ui.wave.divider {
    height: auto;
}

.editorial {
    display: block;
    width: 100%;
    height: 24px;
    max-height: 24px;
    margin: 0;
    z-index: 5;
    bottom: 0;
    position: relative;
    left: 0;
    float: left;
}

.parallax1 > use {
    animation: move-forever1 10s linear infinite;

    &:nth-child(1) {
        animation-delay: -2s;
    }
}
.parallax2 > use {
    animation: move-forever2 8s linear infinite;

    &:nth-child(1) {
        animation-delay: -2s;
    }
}
.parallax3 > use {
    animation: move-forever3 6s linear infinite;

    &:nth-child(1) {
        animation-delay: -2s;
    }
}
.parallax4 > use {
    animation: move-forever4 4s linear infinite;

    &:nth-child(1) {
        animation-delay: -2s;
    }
}
@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever4 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@media only screen and (max-width: 480px) {
    .ui.validation.card {
        box-shadow: none;
    }
}
</style>